<script>
import GlobalVue from '../../helper/Global.vue'
import Gen from '../../helper/Gen'

export default {
  extends: GlobalVue,
  data() {
    return {
      data:{},
      input:{}
    }
  },
  computed: {},
  mounted() {
    this.$emit("ready", this);
    $(document).ready(function() {
      setTimeout(() => {
        $(".form-control").first().focus()
        $(".form-control").keyup(function () {
          if (this.value.length == this.maxLength) {
            $(this).next('.form-control').focus();
          }
        });
      }, 300);
    })
  },
  methods: {
    open(params){
      global.Modal = this
      $.magnificPopup.open({
        items: {src: "#ModalLegalAge"},
        type: 'inline',
        closeOnContentClick: false,
        closeBtnInside: false,
        closeOnBgClick:false,
        enableEscapeKey:false,
        fiexContentPos: true,
        showCloseBtn:false,
        overflowY: 'hidden',
					callbacks: {
						beforeOpen: function() {
							$('body, html').addClass('overflow-hidden');
						},
						beforeClose: function() {
							$('body, html').removeClass('overflow-hidden');
						}
					}
      })
      if(params) {
      }
    },
    submitAge(e){
      if(!this.input.day) return swal("Please input Your date of birth","","warning")
      if(!this.input.month) return swal("Please input Your month of birth","","warning")
      if(!this.input.year) return swal("Please input Your year of birth","","warning")
      let input = {}
      input.day = this.input.day[0]
      input.month = this.input.month[0]
      input.year = this.input.year[0]
      if(e&&e.btnLoading()) return;
      Gen.apirest("/submit-legal-age",input,(err,resp)=>{
        if(e) e.btnUnloading()
        if(err) return swal(err.resp.message,"","warning")
        if(resp.age < 21) return swal("Sorry, you do not meet the age requirements to access this site.","","warning")
        if(resp.age == undefined) return swal("Sorry, you do not meet the age requirements to access this site.","","warning")
        Gen.putStorage("legalAge", resp.age)
        $.magnificPopup.close()
      },"POST")
    },
    regexMonth(text){
      if(!text) return
      var parseText = text.toString()
      var x = parseText.match(/^(0[1-9]|1[0-2])/g);
      this.$set(this.input, 'month', x)
    },
    regexDay(text){
      if(!text) return
      var parseText = text.toString()
      var x = parseText.match(/^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/g);
      this.$set(this.input, 'day', x)
    },
    regexYear(text){
      if(!text) return
      var parseText = text.toString()
      var x = parseText.match(/^(20[0-2][0-9]|19[2-9][0-9])/g);
      this.$set(this.input, 'year', x)
    },
    getAge(birthday){
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    sumAge(birth_month,birth_day,birth_year){
        let today_date = new Date();
        let today_year = today_date.getFullYear();
        let today_month = today_date.getMonth();
        let today_day = today_date.getDate();
        let age = today_year - birth_year;

        if ( today_month < (birth_month - 1))
        {
            age--;
        }
        if (((birth_month - 1) == today_month) && (today_day < birth_day))
        {
            age--;
        }
        return age;
    }
  },
  watch: {}
}
</script>

<template>
<div class="modal1 mfp-hide subscribe-widget" id="ModalLegalAge">
		<div class="divcenter" style="background-color: #FFF; max-width: 500px;">
			<VForm @resp="submitAge">
      <div class="center">
				<h3>Enter Your Birthdate</h3>
				<p class="mb-3">The products sold on the Cape Discovery &amp; Bellissimo site are intended for adults only! By entering, you certify that you are of legal drinking age, at least 21 years old</p>
				<div class="form-row justify-content-center">
          
          <!-- <VInfo ref="info"></VInfo> -->
						<input type="number" name="legal" autofocus v-model="input.day" class="form-control col-md-2 col-4" @change="regexDay(input.day)" placeholder="DD" maxlength="2" max="31">
						<input type="number" name="legal" v-model="input.month" @change="regexMonth(input.month)" class="form-control col-md-2 col-4" placeholder="MM" maxlength="2" max="12">
						<input type="number" name="legal" v-model="input.year" class="form-control col-md-3 col-4" @change="regexYear(input.year)" placeholder="YYYY" maxlength="4" max="2030">

            <LabelError name="legal"></LabelError>
				</div>
			</div>
			<div class="section center nomargin nobg pt-0">
				<button type="submit" class="button button-red btn-loading">Confirm</button>
			</div>
      </VForm>
		</div>
	</div>
</template>